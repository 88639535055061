<template>
    <br />
    <br />
    <!--<span style="color:white;">{{ windowWidth }}</span>-->
<div>
    <p class="col-xs-8 offset-1 blurb textPos">
        The music of Epistorius is an amalgamation of driving rhythms, thundering guitars, evocative synths, and epic orchestra.
        <br />
        Comprised of a group of mages whose primary power is the ability to time travel between pandemics, the group escaped the Black Death in Europe
        in 1350 only to find themselves in Austin, Texas in the year 2020.
        <br />
        Unable to find jobs at the time, they decided to form a band. As the live music scene was non-existent upon arrival, they went straight to the studio
        to start recording and ended up releasing their first record in November 2022.
        <br />
        The group has since decided that this time period is a good one to stay in for a while and is
        currently in the studio recording their second record which is almost complete and set to be released early in 2025.
    </p>
    <br />
    <div class="row">
        <div class="ctr">
            <p class="introtext">&nbsp;&nbsp;&nbsp; The Band</p>
        </div>
    </div>
    <div class="row">
        <div class="ctr">
            <img src="../assets/Xarosius.jpg" class="rounded float-left btmpad lftpad" alt="Band Member 1" style="width:400px">
            <img src="../assets/Qevak.jpg" class="rounded float-right btmpad lftpad" alt="Band Member 2" style="width:400px">
        </div>
    </div>
    <div class="row">
        <div class="ctr">
            <img src="../assets/Zaarn.jpg" class="rounded float-left btmpad lftpad" alt="Band member 3" style="width:400px">
            <img src="../assets/Stanley.jpg" class="rounded float-right btmpad lftpad" alt="Band Member 4" style="width:400px">
        </div>
    </div>
</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'AboutView',
        data() {
            return {
                windowWidth: 0
            };
        },
/*
        mounted() {
            this.windowWidth = window.innerWidth;
            window.onresize = () => { this.windowWidth = window.innerWidth; }  
        }
*/
    });
</script>

<style>
    .blurb {
        font-size: 1.4em;
    }

    .btmpad {
        margin-bottom: 50px;
    }

    .introtext {
        font-size:2.4em;
        font-weight: bold;
    }

    @media screen and (max-width: 575px) {
        .textPos {
            margin-right: 25px;
        }
        
        .lftpad {
            margin-left: 0;
        }
    }

    @media screen and (min-width: 576px) and (max-width: 772px) {
        .textPos {
            margin-right: 50px;
        }


        .lftpad {
            margin-left: 50px;
        }
    }

    @media screen and (min-width: 773px) {
        .textPos {
            margin-right: 100px;
        }

        .lftpad {
            margin-left: 50px;
        }
    }
</style>